import React, { Fragment } from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from '../components/layout'
import SeO from '../components/seo'

import { IoMdStarOutline } from "react-icons/io"
import { GiCardboardBox } from "react-icons/gi"
import { MdPlayCircleFilled } from "react-icons/md"

import { css } from '@emotion/react'
import { colors } from '../styles/constants'
import ToolsList from '../components/toolsList'

const Module = (props) => {
    
    const track = props.data.track.data
    // const trackId = null
    const title = track.name.text
    const desc = track.excerpt
    const thumbnail = track.thumbnail.gatsbyImageData
    const articles = track.articles.filter( item => item.article.document !== null ).map( item => item.article.document) || []
    // console.log("articles", articles)
    const articlesCount = articles.length
    let uniqTools
    let toolsSet = new Set()
    let articlesWithComplexity = 0
    let complexitySum = 0

    if ( articlesCount > 0 ) {
        // Calculate average Complexity for the overall track
        complexitySum = articles.map( article => {
            const complexity = article.data ? article.data.complexity : null
            if ( complexity !== null ) {
                articlesWithComplexity++
                return complexity
            }
            return false
        }).reduce( (sum, value) => sum + value )

        // List a unique set of tools (don't forget to transform Set to array)
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
        let articlesWithTools = articles.filter( article => ( article.data.tools.length > 0 && article.data.tools[0].tool.document !== null ))
        if ( articlesWithTools.length > 0 ) {
            // console.log("Tools to show", articlesWithTools)
            articlesWithTools.forEach( article => (
                article.data.tools.forEach( ({tool}) => {

                    if ( tool.document !== null && tool.document.uid ) {
                        toolsSet.add({
                            'uid': tool.document.uid,
                            'name': tool.document.data.name.text,
                            'logo': tool.document.data.logo.gatsbyImageData,
                        })
                      }
                    return false
                })
            ))

            uniqTools = Array.from(toolsSet).filter( (value, index, self) => 
                index === self.findIndex((t) => (
                    t.place === value.place && t.name === value.name
                ))
            )
        }
        console.log("Final tools Set", uniqTools)
    }

    let complexity = null

    if ( articlesWithComplexity > 0 ) {
        // console.log(complexitySum)
        complexity = Math.round( complexitySum / articlesWithComplexity )
    }

    return (
        <Fragment>
            <SeO 
                title={title}
                description={desc}
            />

            <Layout>
                <div css={articleHead}>
                    <header css={articleHeader}>
                        <span className="tag--dark-box">MODULE</span>
                        <h1 className="content-title">{title}</h1>
                        <p className="content-intro">{desc}</p>
                    </header>

                    {thumbnail &&
                        <figure css={articleThumbnail}>
                            <GatsbyImage image={thumbnail} alt={title} />
                        </figure> 
                    }
                </div>
           
                <div className="container">
                    <div css={contentBoilerplate}>
                        {toolsSet.size > 0 &&
                            <div css={toolsSection}>
                                <ToolsList tools={uniqTools} />
                            </div>
                        }   

                        <div>
                            <GiCardboardBox /> Parcours à {articlesCount} checklists
                        </div>

                        {complexity !== null && <div>
                            <IoMdStarOutline /> Difficulté {complexity}/3
                        </div>}
                    </div>

                    {articles.length > 0 &&
                        <section>
                            {articles.map( (article, index) => (
                                <div 
                                    css={singleArticle}
                                    key={article.uid}
                                >
                                    <div>
                                        <h2>{index + 1}. {article.data.title.text}</h2>
                                        <p>{article.data.desc}</p>
                                        <Link to={`/checklists/${article.uid}`}><MdPlayCircleFilled /> Voir</Link>
                                    </div>
                                    {article.data.tools.length > 0 && article.data.tools[0].tool.document !== null &&
                                        <div css={toolsSection}>
                                            <ToolsList tools={article.data.tools.map( ({tool}) => {
                                                if ( tool.document !== null && tool.document.uid ) {
                                                    return ({
                                                        'uid': tool.document.uid,
                                                        'name': tool.document.data.name.text,
                                                        'logo': tool.document.data.logo.gatsbyImageData,
                                                    })
                                                  }
                                                return false
                                            })} />
                                        </div>
                                    }
                                </div>
                            ))}
                        </section>
                    }
                </div>
            </Layout>
        </Fragment>
    )
}

export default Module

/**
 * CSS in JS
 */

const articleHead = css`
    display: block;
    padding: 0;
    margin: 50px auto;

    @media (min-width: 600px) {
        display: flex;
        justify-content: space-between; // horizontal disposition
        align-items: center; //vertical align
    }
`

const articleHeader = css`
    width: 100%;
    margin-right: 5%;

    @media (min-width: 600px) {
        width: 45%;
    }
`

const articleThumbnail = css`
    width: 100%; 

    @media (min-width: 600px) {
        width: 45%;
    }
`

const contentBoilerplate = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 0 0;
    padding: 20px 20px 15px;
    border: 1px solid ${colors.whitegrey};
    border-radius: 5px;

    @media (min-width: 600px) {
        margin: 10px 0 100px 0;
    }
`

const singleArticle = css`
    padding: 30px 30px 20px;
    margin: 30px auto;
    box-shadow: ${colors.shadow};
    border-radius: ${colors.radius};
    display: flex;
    justify-content: space-between;
    align-items: center;

    div:first-of-type {
        margin-right: 20px;
    } 

    h2 {
        font-size: 20px;
        font-weight: 400;
        margin: 0 0 10px;
    }

    p {
        font-size: 16px;
        line-height: 1.5;
        margin: 0;
        max-width: 500px;
    }

    a {
        background-color: ${colors.accent2};
        padding: 5px 15px;
        border-radius: 5px;
        display: inline-block;
        color: ${colors.black};
        margin-top: 10px;

        &:hover {
            text-decoration: none;
        }

        svg {
            margin-right: 5px;
        }
    }


    .gatsby-image-wrapper {
        margin-left: 20px;

        &:not(first-of-type) {
            margin-left: -10px;
        }
    }
    
    img {
        border-radius: 100px;
        border: 4px solid ${colors.whitegrey};
    }
`

const toolsSection = css`
    display: flex;
    flex-wrap: nowrap;

    .gatsby-image-wrapper:not(first-of-type) {
        margin-right: -10px;
    }

    img {
        border-radius: 100px;
        border: 4px solid ${colors.whitegrey};
    }
`

/**
 * GraphQL Query (single playlist), receives id/slug as context
 */

export const trackQuery = graphql`
    query TrackQuery($uid: String!) {
        track: prismicTrack(uid: {eq: $uid}) {
            uid
            data {
                name {
                    text
                }
                excerpt
                thumbnail {
                    gatsbyImageData
                }
                articles {
                    article {
                        document {
                            ... on PrismicArticle {
                                ...TrackArticle
                            }
                        }
                    }
                }
            }
        }
    }
`